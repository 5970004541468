import { Dialog } from "@app/components/dialog";
import { ProcessingModal } from "@app/components/processing-modal";
import { useAccountManager } from "@app/hooks/use-account-manager";
import { useEffect, useState } from "react";

import PhoneIcon from "@app/icons/phone.svg?react";
import PhoneAltIcon from "@app/icons/phone-alt.svg?react";
import WhatsAppIcon from "@app/icons/whatsapp.svg?react";
import EmailIcon from "@app/icons/email.svg?react";

import styles from "./index.module.css";
import { Button } from "@app/components/button";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { OTPMethod, useOTP } from "../../use-otp";
import { ApiErrors } from "@app/components/api-errors";
import { FormErrors } from "@app/utils/get-form-errors";

const DEFAULT_TIMEOUT_PERIOD = 1000 * 45; // 45 seconds

export const ResendOTPModal = ({
	onClose,
	onConfirm,
	onResend,
	contactNumber,
	variant = "standard",
	isOpen = true,
}: {
	onClose: () => void;
	onConfirm: () => void;
	onResend?: (type: OTPMethod) => Promise<FormErrors | undefined>;
	contactNumber?: string;
	variant?: "device" | "standard" | "unauthed";
	isOpen?: boolean;
}) => {
	const [apiErrors, setApiErrors] = useState<string[]>([]);
	const { otpDetails, createOTPDevice, requestOTP } = useOTP();
	const [timeoutValue, setTimeoutValue] = useState(DEFAULT_TIMEOUT_PERIOD);
	const isMobile = useMediaQuery();
	const { data: accountManager } = useAccountManager();
	const [showResending, setShowResending] = useState(false);

	useEffect(() => {
		if (timeoutValue <= 0) return;
		const timeout = setTimeout(() => {
			setTimeoutValue((current) => current - 1000);
		}, 1000);
		return () => clearTimeout(timeout);
	});

	const handleResend = async (type: OTPMethod) => {
		setShowResending(true);
		const number = contactNumber || otpDetails?.contact_number;
		const errors = onResend
			? await onResend(type)
			: variant === "device" && number
				? await createOTPDevice({
						contact_number: number,
						message_channel: type,
					})
				: await requestOTP(type);
		if (errors) {
			setApiErrors(errors.apiErrors);
		} else {
			onConfirm();
		}
		setShowResending(false);
	};

	const isEnabled = timeoutValue <= 0;

	return (
		<>
			<Dialog
				bottomsheet={isMobile}
				size="fit"
				isOpen={isOpen && !showResending}
				title="Didn't receive an OTP?"
				actions={
					<Button centered onClick={onClose}>
						Back
					</Button>
				}
			>
				<p className={styles.description}>
					{isEnabled
						? "Resend code"
						: `Resend code in ${Math.floor(timeoutValue / 1000)}s`}
				</p>
				<div className={styles.buttons}>
					<button
						onClick={() => handleResend("sms")}
						disabled={!isEnabled}
						type="button"
						className={styles.button}
					>
						<PhoneIcon width={20} height={20} />
						Send via SMS
					</button>
					<button
						onClick={() => handleResend("whatsapp")}
						disabled={!isEnabled}
						type="button"
						className={styles.button}
					>
						<WhatsAppIcon width={20} height={20} />
						Send via Whatsapp
					</button>
				</div>
				<ApiErrors className={styles.errors} errors={apiErrors} />
				<div className={styles.divider} />
				<p className={styles.description}>Lost your number? Contact us</p>
				<div className={styles.contactSection}>
					{isMobile ? (
						<a
							className={styles.button}
							href={`mailto:${accountManager?.email ?? "info@futureforex.co.za"}`}
						>
							<EmailIcon color="#56A7A2" width={20} height={20} />
							Email
						</a>
					) : (
						<div className={styles.contactItem}>
							<EmailIcon color="#56A7A2" width={16} height={16} />
							<a
								href={`mailto:${accountManager?.email ?? "info@futureforex.co.za"}`}
							>
								{accountManager?.email ?? "info@futureforex.co.za"}
							</a>
						</div>
					)}

					{isMobile ? (
						<a
							className={styles.button}
							href={`tel:${accountManager?.contact_number ?? "021 518 0558"}`}
						>
							<PhoneAltIcon color="#56A7A2" width={20} height={20} />
							Phone
						</a>
					) : (
						<div className={styles.contactItem}>
							<PhoneAltIcon color="#56A7A2" width={16} height={16} />
							<a
								href={`tel:${accountManager?.contact_number ?? "021 518 0558"}`}
							>
								{accountManager?.email ?? "021 518 0558"}
							</a>
						</div>
					)}
				</div>
			</Dialog>

			<ProcessingModal
				isOpen={isOpen && showResending}
				showSuccess={false}
				processingText="Resending OTP"
			/>
		</>
	);
};
