import { useState } from "react";
import { DeviceModal } from "../modals/device-modal";
import { OTPInputModal } from "../modals/otp-input-modal";
import { ResendOTPModal } from "../modals/resend-otp-modal";
import { ConfirmationEnableOtpModal } from "../modals/confirmation-enable-otp-modal";
import { ConfirmedEnableModal } from "../modals/confirmed-enable-modal";
import { OTPMethod } from "../use-otp";

export const EnableOTPDeviceFlow = ({
	onComplete,
	onClose,
}: {
	onComplete: () => void;
	onClose: () => void;
}) => {
	const [method, setMethod] = useState<OTPMethod>();
	const [status, setStatus] = useState<
		"confirmation" | "device" | "otp" | "resend" | "confirmed"
	>("confirmation");
	const [contactNumber, setContactNumber] = useState("");

	return (
		<>
			{status === "confirmation" && (
				<ConfirmationEnableOtpModal
					closeText="Cancel"
					onBack={onClose}
					onClose={onClose}
					onConfirm={() => {
						setStatus("device");
					}}
				/>
			)}
			{status === "device" && (
				<DeviceModal
					onClose={onClose}
					onConfirm={(contactNumber, method) => {
						setContactNumber(contactNumber);
						setMethod(method);
						setStatus("otp");
					}}
				/>
			)}

			<OTPInputModal
				contactNumber={contactNumber}
				method={method}
				isOpen={status === "otp"}
				onResend={() => {
					setStatus("resend");
				}}
				variant="device"
				onClose={() => {
					setStatus("device");
				}}
				onConfirm={() => {
					setStatus("confirmed");
				}}
			/>

			{status === "resend" && (
				<ResendOTPModal
					contactNumber={contactNumber}
					variant="device"
					onConfirm={() => {
						setStatus("otp");
					}}
					onClose={() => {
						setStatus("otp");
					}}
				/>
			)}

			{status === "confirmed" && <ConfirmedEnableModal onClose={onComplete} />}
		</>
	);
};
