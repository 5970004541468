import { useState } from "react";
import { DeviceModal } from "../modals/device-modal";
import { OTPInputModal } from "../modals/otp-input-modal";
import { ResendOTPModal } from "../modals/resend-otp-modal";
import { TrustDeviceFlow } from "../trust-device-flow";
import { OTPMethod } from "../use-otp";
import { ConfirmedModal } from "../modals/confirmed-modal";

export const InitOTPDeviceFlow = ({
	onComplete,
	onClose,
}: {
	onComplete: () => void;
	onClose: () => void;
}) => {
	const [methodName, setMethodName] = useState<OTPMethod>();
	const [contactNumber, setContactNumber] = useState("");
	const [step, setStep] = useState<
		"device" | "otp" | "resend" | "success" | "trust"
	>("device");

	return (
		<>
			{step === "device" && (
				<DeviceModal
					onClose={onClose}
					onConfirm={(contactNumber, methodName) => {
						setContactNumber(contactNumber);
						setMethodName(methodName);
						setStep("otp");
					}}
				/>
			)}
			<OTPInputModal
				isOpen={step === "otp"}
				method={methodName}
				contactNumber={contactNumber}
				onResend={() => {
					setStep("resend");
				}}
				variant="device"
				onClose={() => {
					setStep("device");
				}}
				onConfirm={() => {
					setStep("success");
				}}
			/>

			{step === "resend" && (
				<ResendOTPModal
					contactNumber={contactNumber}
					variant="device"
					onConfirm={() => {
						setStep("otp");
					}}
					onClose={() => {
						setStep("otp");
					}}
				/>
			)}
			{step === "success" && (
				<ConfirmedModal
					noCloseButton
					onClose={() => {
						setStep("trust");
					}}
					completeText="Continue"
					title="OTP enabled"
				/>
			)}
			{step === "trust" && <TrustDeviceFlow onComplete={onComplete} />}
		</>
	);
};
