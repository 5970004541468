import { ReactNode, useEffect, useRef, useState } from "react";

import { Dialog } from "@app/components/dialog";
import type { DocumentUploadCandidate } from "@app/entities";
import { useStateWithCallback } from "@app/hooks/use-state-with-callback";

import { DocumentUploadItem } from "./document-upload-item";

import { ApiErrors } from "@app/components/api-errors";
import { FiUploadCloud } from "react-icons/fi";
import styles from "./index.module.css";

import { Button } from "@app/components/button";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { AxiosProgressEvent } from "axios";

const DEFAULT_MAX_FILES = 25;

export const DocumentUploadModal = ({
	maxFiles = DEFAULT_MAX_FILES,
	title,
	description,
	variant = "default",
	isOpen,
	...props
}: {
	title?: string;
	description?: ReactNode;
	candidates?: DocumentUploadCandidate[];
	className?: string;
	error?: string;
	iconClassName?: string;
	maxFiles?: number;
	multiple?: boolean;
	showSingleButton?: boolean;
	uploadEnabled?: boolean;
	onCancelUpload?: () => void;
	onClose?: (items: DocumentUploadCandidate[]) => void;
	onConfirmUpload?: () => void;
	onDeleteItem?: (index: number, item: DocumentUploadCandidate) => void;
	onUpload?: (
		index: number,
		file: File | undefined,
		onSetUploadComplete: (index: number, id?: number | string) => void,
		onSetUploadProgress: (progressEvent: AxiosProgressEvent) => void,
		onUploadError: (error: string, index: number) => void,
	) => void;
	variant?: "profile" | "default";
	isOpen: boolean;
}) => {
	const inputFileRef = useRef<HTMLInputElement>(null);

	const [uploadPercent, setUploadPercent] = useState(0);
	const [uploadIndex, setUploadIndex] = useState(-1);
	const [uploadFiles, setUploadFiles] = useStateWithCallback<
		DocumentUploadCandidate[]
	>([]);
	const [uploadedFiles, setUploadedFiles] = useState<DocumentUploadCandidate[]>(
		[],
	);
	const [existingFiles, setExistingFiles] = useState<DocumentUploadCandidate[]>(
		props.candidates ?? [],
	);
	const [dragHover, setDragHover] = useState(false);
	const dragLeaveTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

	const setUploadComplete = (index: number, id?: number | string) => {
		const newState = [...uploadFiles];

		newState[index].complete = true;
		newState[index].id = id;

		setUploadedFiles(newState);
	};

	const onAddUploadFiles = (
		fileList: FileList | DocumentUploadCandidate[] | null,
	) => {
		setDragHover(false);
		if (fileList !== null) {
			const files: DocumentUploadCandidate[] = [];
			const isFileArray = Array.isArray(fileList);

			for (let i = 0; i < fileList.length; i++) {
				const fileItem: File | undefined | null = isFileArray
					? fileList[i].file
					: fileList.item(i);

				const fileError = isFileArray ? fileList[i].error : undefined;

				if (
					fileItem &&
					((!props.multiple && files.length < 1) || props.multiple)
				) {
					files.push({
						file: fileItem,
						error: fileError,
						load: 0,
					});
				}
			}

			setUploadFiles([...uploadFiles, ...files]);

			// Clear the value of the input to allow duplicate files to be uploaded
			if (inputFileRef.current) {
				inputFileRef.current.value = "";
			}
		}
	};

	const onCloseModal = () => {
		props.onClose?.(uploadedFiles);
	};

	const onDeleteCandidate = (index: number) => {
		const newState = [...uploadFiles];
		const candidate = uploadFiles[index];

		if (uploadFiles[index].complete) {
			props.onDeleteItem?.(index, uploadFiles[index]);
		} else if (!candidate.complete) {
			props.onCancelUpload?.();
			setUploadIndex(-1);
		}

		if (isSingleFileUpload) {
			setUploadFiles([]);
			setExistingFiles([]);
			setUploadPercent(0);
			setUploadIndex(-1);
		} else {
			// Default case for yet un-uploaded files is to just mark as deleted
			newState[index].deleted = true;
			newState[index].error = undefined;

			setUploadFiles(newState, () => {
				if (!newState[index].error) {
					onAddUploadFiles(getRetryDocuments(uploadFiles));
				}
			});
		}
	};

	const getRetryDocuments = (docsState: DocumentUploadCandidate[]) => {
		const actualExistingDocs = existingFiles.filter(
			(x) => !x.deleted && !x.error,
		).length;
		const actualUploadDocs = uploadFiles.filter(
			(x) => !x.deleted && !x.error,
		).length;

		const failedDocs = docsState.filter((x) => !x.deleted && x.error);

		const retryDocs = failedDocs.map<DocumentUploadCandidate>((y, i) => {
			return {
				file: y.file
					? new File([y.file], y.file.name, {
							type: y.file.type,
							lastModified: y.file.lastModified + 1,
						})
					: undefined,
				error: maxFiles
					? actualExistingDocs +
							actualUploadDocs +
							(failedDocs.length - 1) +
							i >
						maxFiles
						? y.error
						: undefined
					: undefined,
			};
		});

		for (const doc of failedDocs) {
			doc.deleted = true;
			delete doc.file;
		}

		return retryDocs;
	};

	const onDeleteExistingCandidate = (index: number) => {
		const newState = [...existingFiles];

		const candidate = existingFiles[index];

		if (candidate?.existing) {
			props.onDeleteItem?.(index, candidate);
		}

		newState[index].deleted = true;

		if (!newState[index].error) {
			onAddUploadFiles(getRetryDocuments(uploadFiles));
		}

		setExistingFiles(newState);
	};

	const onUpload = (
		index: number,
		file: File | undefined,
		onSetUploadComplete: (index: number, id?: number | string) => void,
		onSetUploadProgress: (progressEvent: AxiosProgressEvent) => void,
		onUploadError: (error: string, index: number) => void,
	) => {
		if (props.onUpload) {
			props.onUpload(
				index,
				file,
				onSetUploadComplete,
				onSetUploadProgress,
				onUploadError,
			);
		}
	};

	const onUploadError = (error?: string, index?: number) => {
		const newUpload = [...uploadFiles];
		newUpload[index !== undefined ? index : uploadIndex].error = error;
		setUploadFiles(newUpload);
		onUploadNextFile();
	};

	const onUploadNextFile = () => {
		let newUploading = uploadIndex + 1;

		while (
			newUploading < uploadFiles.length &&
			(uploadFiles[newUploading].deleted ||
				uploadFiles[newUploading].error ||
				!uploadFiles[newUploading].file)
		) {
			newUploading += 1;
		}

		if (newUploading <= uploadFiles.length - 1) {
			setUploadPercent(0);
			setUploadIndex(newUploading);
		}
	};

	useEffect(() => {
		if (
			uploadFiles.length > 0 &&
			(uploadIndex === -1 || uploadPercent === 100)
		) {
			onUploadNextFile();
		}
	}, [uploadFiles.length, uploadIndex, uploadPercent]);

	useEffect(() => {
		if (uploadIndex > -1) {
			onUpload(
				uploadIndex,
				uploadFiles[uploadIndex].file,
				(index: number, id?: number | string) => {
					setUploadComplete(index, id);
				},
				(progressEvent: AxiosProgressEvent) => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / (progressEvent.total || 1),
					);
					setUploadPercent(percentCompleted);
				},
				(error: string, index: number) => {
					onUploadError(error, index);
				},
			);
		}
	}, [uploadIndex]);

	const viewProps = {
		...props,
		dragHover,
		existingFiles,
		totalAvailableFiles:
			existingFiles.filter((x) => !x.deleted).length +
			uploadFiles.filter((x) => !x.deleted).length,
		inputFileRef,
		uploadFiles,
		uploadIndex,
		uploadCandidate: uploadFiles[uploadIndex],
		uploadEnabled:
			(maxFiles === undefined ||
				existingFiles.filter((x) => !x.deleted && !x.error).length +
					uploadFiles.filter((x) => !x.deleted && !x.error).length <=
					maxFiles) &&
			uploadFiles.filter((x) => !x.deleted && !x.existing && !x.complete)
				.length === 0,
		onCloseModal,
		onDeleteCandidate,
		onDeleteExistingCandidate,
	};

	const isMobile = useMediaQuery();

	const hasExistingDocuments =
		viewProps.existingFiles.filter((x) => !x.deleted).length > 0;
	const hasUploadingDocuments =
		viewProps.uploadFiles.filter((x) => !x.deleted).length > 0;

	const errors = [
		...viewProps.existingFiles.map((x) => x.error),
		...viewProps.uploadFiles.map((x) => x.error),
	].filter((current) => !!current);

	const showMaxFilesError =
		maxFiles !== undefined &&
		(viewProps.totalAvailableFiles ?? 0) > (maxFiles ?? 0);

	const isSingleFileUpload = maxFiles === 1 && !viewProps.multiple;
	const hasCurrent = hasExistingDocuments || hasUploadingDocuments;

	return (
		<Dialog
			onClose={viewProps.onCloseModal}
			fullscreen={isMobile}
			title={title ?? "Upload document"}
			description={description}
			isOpen={isOpen}
			className={viewProps.className}
			actions={
				viewProps.showSingleButton ? (
					<Button
						centered
						minWidth={110}
						disabled={!viewProps.uploadEnabled}
						onClick={() => viewProps?.onConfirmUpload?.()}
					>
						Done
					</Button>
				) : (
					<>
						<Button
							variant="secondary"
							onClick={() => viewProps?.onCloseModal?.()}
						>
							Cancel
						</Button>
						<Button
							disabled={!props.uploadEnabled}
							onClick={() => props?.onConfirmUpload?.()}
						>
							Upload
						</Button>
					</>
				)
			}
		>
			{isSingleFileUpload && hasCurrent ? null : (
				<div
					data-is-dragging={viewProps.dragHover}
					className={styles.dropArea}
					onDrop={(e) => {
						onAddUploadFiles(e.dataTransfer.files);
						e.preventDefault();
						e.stopPropagation();
					}}
					onDragOver={(e) => {
						e.preventDefault();
						e.stopPropagation();
						if (dragLeaveTimeout.current) {
							clearTimeout(dragLeaveTimeout.current);
						}
						setDragHover(true);
					}}
					onDragLeave={(e) => {
						e.preventDefault();
						e.stopPropagation();
						dragLeaveTimeout.current = setTimeout(() => {
							setDragHover(false);
						}, 100);
					}}
					onClick={() => (viewProps.inputFileRef.current as any).click()}
				>
					<FiUploadCloud size={24} color="inherit" />
					<p className={styles.dropPrompt}>
						{isMobile ? (
							<>
								<span className={styles.clickToUpload}>Tap to upload</span> a
								file
							</>
						) : (
							<div>
								Drag and drop
								<br />
								or <span className={styles.clickToUpload}>click to upload</span>{" "}
								a file
							</div>
						)}
					</p>

					<p className={styles.types}>
						{variant === "profile"
							? "PNG, JPEG under 10MB"
							: "PNG, JPEG, PDF under 10MB"}
					</p>

					<input
						aria-label="File upload"
						multiple={props.multiple}
						type="file"
						accept={
							variant === "profile" ? "image/*" : "image/*, application/pdf"
						}
						id="file"
						onChange={(e) => onAddUploadFiles(e.target.files)}
						ref={viewProps.inputFileRef}
						style={{ display: "none" }}
					/>
				</div>
			)}
			<div>
				{hasCurrent && (
					<div
						id="scroll-inner"
						className="-mr-5 mb-6 mt-6 flex max-h-80 flex-col overflow-y-auto pr-4"
					>
						<ul className={styles.list}>
							{hasExistingDocuments &&
								viewProps.existingFiles.map((current, index) => {
									if (current.deleted) return null;
									return (
										<DocumentUploadItem
											className={index > 0 ? "mt-6" : ""}
											key={index}
											file={current.file}
											fileName={current.fileName}
											fileSize={current.fileSize}
											error={current.error}
											existing
											load={current.load}
											hideBar
											extension={current.fileExtension}
											onDelete={() => onDeleteExistingCandidate(index)}
										/>
									);
								})}
							{hasUploadingDocuments &&
								viewProps.uploadFiles.map((current, i) => {
									if (current.deleted) return null;
									return (
										<>
											<DocumentUploadItem
												className={
													viewProps.existingFiles.length + i > 0 ? "mt-6" : ""
												}
												key={i}
												file={current.file}
												error={current.error}
												onDelete={() => onDeleteCandidate(i)}
												load={
													current.complete
														? 100
														: viewProps.uploadIndex !== i
															? 0
															: viewProps.uploadIndex === i
																? uploadPercent
																: 100
												}
											/>
										</>
									);
								})}
						</ul>
					</div>
				)}
			</div>

			<ApiErrors
				errors={
					showMaxFilesError
						? [" No more than 5 files can be added per document type"]
						: [errors[0]]
				}
			/>
		</Dialog>
	);
};
