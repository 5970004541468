import { ConfirmedModal } from "../confirmed-modal";

export const ConfirmedDeviceTrustedModal = ({
	onClose,
	isOpen = true,
}: {
	onClose: () => void;
	isOpen?: boolean;
}) => {
	return (
		<ConfirmedModal
			isOpen={isOpen}
			onClose={onClose}
			title="Device trusted"
			completeText="Continue"
			description="This is now a trusted device and OTP authentication will not be required for future logins."
		/>
	);
};
