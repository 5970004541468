import { DocumentUploadModal } from "@app/components/document-upload-modal";
import {
	ComponentPropsWithoutRef,
	ReactNode,
	useEffect,
	useState,
} from "react";
import {
	OnboardingDocumentType,
	useDocuments,
	Document,
} from "./use-documents";
import { abortRequests } from "@app/services";
import { getFormErrors } from "@app/utils/get-form-errors";
import { AxiosProgressEvent } from "axios";
import { FieldError as RHFFieldError } from "react-hook-form";
import { DocumentFieldContent } from "./document-field-content";

export const NewDocumentField = ({
	type,
	label,
	value,
	onChange,
	tooltip,
	error,
	tooltipWidth = 260,
	disabled,
	className,
	multiple = false,
	...rest
}: {
	type: OnboardingDocumentType;
	label: string;
	value?: string | null;
	onChange?: () => Promise<void>;
	tooltip?: ReactNode;
	error?: RHFFieldError;
	tooltipWidth?: number;
	disabled?: boolean;
	className?: string;
	multiple?: boolean;
} & Omit<ComponentPropsWithoutRef<"div">, "onChange">) => {
	const [uploadedFiles, setUploadedFiles] = useState<Array<File>>([]);
	const [removeId, setRemoveId] = useState<string | null>(null);
	const [downloadId, setDownloadId] = useState<string | null>(null);
	const [files, setFiles] = useState<Array<Document>>([]);
	const [showUpload, setShowUpload] = useState(false);
	const {
		uploadDocument,
		downloadDocument,
		mutate,
		data,
		deleteDocument,
		isLoading,
	} = useDocuments(type);

	const handleRemove = async (documentId: string) => {
		setRemoveId(documentId);
		await deleteDocument(documentId);
		await mutate();
		await onChange?.();
		setRemoveId(null);
	};

	const handleDownload = async (documentId: string) => {
		try {
			setDownloadId(documentId);
			await downloadDocument(documentId);
		} catch (error) {
			// TODO: handle error
		} finally {
			setDownloadId(null);
		}
	};

	useEffect(() => {
		if (!data) return;
		const filteredata = data.filter((file) => file.document_type === type);
		setFiles(filteredata);
	}, [data, type]);

	return (
		<>
			<DocumentFieldContent
				{...rest}
				label={label}
				tooltip={tooltip}
				tooltipWidth={tooltipWidth}
				error={error}
				disabled={disabled}
				className={className}
				multiple={multiple}
				files={files}
				uploadedFiles={uploadedFiles}
				isLoading={isLoading}
				removeId={removeId}
				downloadId={downloadId}
				onShowUpload={() => setShowUpload(true)}
				onRemove={handleRemove}
				onDownload={handleDownload}
			/>

			<DocumentUploadModal
				isOpen={showUpload}
				title={label}
				description={tooltip}
				multiple
				showSingleButton
				onClose={() => setShowUpload(false)}
				onConfirmUpload={async () => {
					await onChange?.();
					setShowUpload(false);
				}}
				onCancelUpload={() => {
					abortRequests();
				}}
				onDeleteItem={(index) => {
					if (data) {
						handleRemove(files[index].document_id);
					}
				}}
				onUpload={async (
					index: number,
					file: File | undefined,
					onSetUploadComplete: (index: number, id?: number | string) => void,
					onSetUploadProgress: (progressEvent: AxiosProgressEvent) => void,
					onUploadError: (error: string, index: number) => void,
				) => {
					if (!file) return;
					try {
						await uploadDocument(file, onSetUploadProgress);
						setUploadedFiles((files) => [...files, file]);
						onSetUploadComplete(index);
						mutate();
					} catch (error) {
						if (error) {
							const { fieldErrors, apiErrors } = getFormErrors(error);
							if (apiErrors.length > 0) {
								onUploadError(apiErrors[0], index);
								return;
							}

							if (fieldErrors.length > 0) {
								onUploadError(fieldErrors[0].message, index);
								return;
							}

							const errorMessage = apiErrors[0] ?? "Upload failed";
							onUploadError(errorMessage, index);
						}
					}
				}}
			/>
		</>
	);
};
