import { useState } from "react";
import { ConfirmedDeviceTrustedModal } from "../modals/confirmed-device-trusted-modal";
import { ConfirmationTrustDeviceModal } from "../modals/confirmation-trust-device-modal";
import { ConfirmedDeviceNotTrustedModal } from "../modals/confirmed-device-not-trusted-modal";

export const TrustDeviceFlow = ({
	onComplete,
}: {
	onComplete: () => void;
}) => {
	const [showTrustDeviceModal, setShowTrustDeviceModal] = useState(true);
	const [showConfirmedTrustDeviceModal, setShowConfirmedTrustDeviceModal] =
		useState(false);
	const [showConfimedNotTrustDeviceModal, setShowConfimedNotTrustDeviceModal] =
		useState(false);
	return (
		<>
			{/* Step 1: Confirm that you want to trust this device */}

			<ConfirmationTrustDeviceModal
				isOpen={showTrustDeviceModal}
				onConfirm={() => {
					setShowTrustDeviceModal(false);
					setShowConfirmedTrustDeviceModal(true);
				}}
				onReject={() => {
					setShowTrustDeviceModal(false);
					setShowConfimedNotTrustDeviceModal(true);
				}}
			/>

			{/* Step 2: Confirmation result */}
			<ConfirmedDeviceTrustedModal
				isOpen={showConfirmedTrustDeviceModal}
				onClose={onComplete}
			/>

			<ConfirmedDeviceNotTrustedModal
				isOpen={showConfimedNotTrustDeviceModal}
				onClose={onComplete}
			/>
		</>
	);
};
