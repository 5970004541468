import { useEffect, useRef } from "react";
import { useShowToast } from "@app/helpers";
import { toast } from "react-toastify";

const CONNECTION_UNSTABLE_TOAST_ID = "connection-unstable";
const CONNECTION_LOST_TOAST_ID = "connection-lost";
const CONNECTION_RESTORED_TOAST_ID = "connection-restored";
const STATUS_CHANGE_DELAY = 5000; // 5 seconds delay before showing status change
const STABILITY_WINDOW = 10000; // 10 second window for checking stability
const MAX_STATUS_CHANGES = 3; // Maximum number of status changes before considering unstable
const STABILITY_CHECK_INTERVAL = 10000; // 10 second interval to check if connection has stabilized

// Type definition for Network Information API
interface NetworkInformation extends EventTarget {
	effectiveType: "slow-2g" | "2g" | "3g" | "4g";
	addEventListener: (type: "change", callback: (event: Event) => void) => void;
	removeEventListener: (
		type: "change",
		callback: (event: Event) => void,
	) => void;
}

declare global {
	interface Navigator {
		connection?: NetworkInformation;
	}
}

interface ConnectionEvent {
	timestamp: number;
	isOnline: boolean;
}

export const ConnectionMonitor = () => {
	const [showToast] = useShowToast();
	const lastConnectionStatus = useRef<"online" | "unstable" | "offline">(
		navigator.onLine ? "online" : "offline",
	);
	const isVisible = useRef(true);
	const statusChangeTimer = useRef<NodeJS.Timeout | null>(null);
	const connectionEvents = useRef<ConnectionEvent[]>([]);
	const stabilityCheckInterval = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		const dismissConnectionToasts = () => {
			toast.dismiss(CONNECTION_LOST_TOAST_ID);
			toast.dismiss(CONNECTION_UNSTABLE_TOAST_ID);
			toast.dismiss(CONNECTION_RESTORED_TOAST_ID);
		};

		const clearStatusChangeTimer = () => {
			if (statusChangeTimer.current) {
				clearTimeout(statusChangeTimer.current);
				statusChangeTimer.current = null;
			}
		};

		const clearStabilityCheckInterval = () => {
			if (stabilityCheckInterval.current) {
				clearInterval(stabilityCheckInterval.current);
				stabilityCheckInterval.current = null;
			}
		};

		const isConnectionUnstable = () => {
			const now = Date.now();
			// Remove events older than the stability window
			connectionEvents.current = connectionEvents.current.filter(
				(event) => now - event.timestamp <= STABILITY_WINDOW,
			);
			return connectionEvents.current.length > MAX_STATUS_CHANGES;
		};

		const startStabilityCheck = () => {
			clearStabilityCheckInterval();
			stabilityCheckInterval.current = setInterval(() => {
				if (!isConnectionUnstable() && navigator.onLine) {
					clearStabilityCheckInterval();
					dismissConnectionToasts();
					showToast(
						"Connection restored",
						"success",
						CONNECTION_RESTORED_TOAST_ID,
					);
					lastConnectionStatus.current = "online";
					checkConnectionQuality();
				}
			}, STABILITY_CHECK_INTERVAL);
		};

		const recordConnectionChange = () => {
			const now = Date.now();
			connectionEvents.current.push({
				timestamp: now,
				isOnline: navigator.onLine,
			});

			if (isConnectionUnstable()) {
				dismissConnectionToasts();
				showToast(
					"Internet connection unstable",
					"unstable",
					CONNECTION_UNSTABLE_TOAST_ID,
				);
				lastConnectionStatus.current = "unstable";
				startStabilityCheck();
				return true;
			}
			return false;
		};

		const checkConnectionQuality = () => {
			const connection = navigator.connection;
			if (connection && ["slow-2g", "2g"].includes(connection.effectiveType)) {
				if (lastConnectionStatus.current !== "unstable") {
					showToast(
						"Internet connection unstable",
						"unstable",
						CONNECTION_UNSTABLE_TOAST_ID,
					);
					lastConnectionStatus.current = "unstable";
				}
			} else if (lastConnectionStatus.current === "unstable") {
				dismissConnectionToasts();
				lastConnectionStatus.current = "online";
			}
		};

		const handleOnline = () => {
			clearStatusChangeTimer();
			if (recordConnectionChange()) return; // Don't proceed if connection is unstable

			if (lastConnectionStatus.current === "offline") {
				statusChangeTimer.current = setTimeout(() => {
					if (navigator.onLine) {
						dismissConnectionToasts();
						showToast(
							"Connection restored",
							"success",
							CONNECTION_RESTORED_TOAST_ID,
						);
						lastConnectionStatus.current = "online";
						checkConnectionQuality();
					}
				}, STATUS_CHANGE_DELAY);
			}
		};

		const handleOffline = () => {
			clearStatusChangeTimer();
			if (recordConnectionChange()) return; // Don't proceed if connection is unstable

			if (lastConnectionStatus.current === "online") {
				statusChangeTimer.current = setTimeout(() => {
					if (!navigator.onLine) {
						showToast(
							"Internet connection lost",
							"offline",
							CONNECTION_LOST_TOAST_ID,
						);
						lastConnectionStatus.current = "offline";
					}
				}, STATUS_CHANGE_DELAY);
			}
		};

		const handleVisibilityChange = () => {
			isVisible.current = document.visibilityState === "visible";
			if (isVisible.current) {
				const isCurrentlyOnline = navigator.onLine;
				if (isCurrentlyOnline !== (lastConnectionStatus.current === "online")) {
					if (isCurrentlyOnline) {
						handleOnline();
					} else {
						handleOffline();
					}
				}
			}
		};

		// Set up event listeners
		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);
		document.addEventListener("visibilitychange", handleVisibilityChange);

		if (navigator.connection) {
			navigator.connection.addEventListener("change", checkConnectionQuality);
		}

		handleVisibilityChange();

		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			if (navigator.connection) {
				navigator.connection.removeEventListener(
					"change",
					checkConnectionQuality,
				);
			}
			clearStatusChangeTimer();
			clearStabilityCheckInterval();
			dismissConnectionToasts();
		};
	}, [showToast]);

	return null;
};
